import { Link } from '../common'
import flower from '../../assets/flower.svg'
import asterisk from '../../assets/asterisk.svg'
import students from '../../assets/students.webp'
import students2 from '../../assets/students2.webp'
import { Box, Heading, Image, Text } from '@chakra-ui/react'
import { DEVELOPER_APPLICATION_FORM_URL } from '../../constants'

export const HeroSection: React.FC = () => {
  return (
    <Box
      data-testid="home-hero"
      maxW="1750px"
      bg="orange.600"
      textAlign="center"
      position="relative"
      pt={{ base: '60px', lg: '100px' }}
    >
      <Box width={{ md: '80%' }} marginX="auto">
        <Box px="8">
          <Heading
            as="h2"
            maxW={{ xl: '600px', md: '400px', base: '300px' }}
            marginX="auto"
            color="black"
            lineHeight="1"
            fontWeight="bold"
            whiteSpace="normal"
            textTransform="uppercase"
          >
            Excel in tech with expert mentorship
          </Heading>
          <Text
            maxW="600px"
            color="black"
            marginX="auto"
            whiteSpace="normal"
            fontSize={{ base: 'md', md: 'lg' }}
            pt={{ md: '8', base: '6' }}
          >
            Are you interested in reaching world-class proficiency in data
            analysis, web development, frontend, backend engineering, or even
            fullstack engineering?
          </Text>
          <Box mt={{ base: '10', md: '12' }} mb={{ base: '16', md: '28' }}>
            <Link
              to={DEVELOPER_APPLICATION_FORM_URL}
              fontSize={{ md: 'md', base: 'sm' }}
              color="white"
              fontWeight="medium"
              textDecoration="none"
              bgColor="orange.400"
              minWidth="165px"
              borderRadius="12px"
              _hover={{ textDecoration: 'none', bgColor: 'orange.800' }}
              py={{ base: '3', md: '4' }}
              px={{ base: '5', md: '8' }}
            >
              REGISTER NOW
            </Link>
          </Box>
        </Box>
        <Box mt={{ md: '8', base: '10' }}>
          <Image
            width="full"
            src={students}
            alt="Hero Image"
            display={{ base: 'block', md: 'none' }}
          />
          <Image
            width="full"
            src={students2}
            display={{ base: 'none', md: 'block' }}
          />
        </Box>
      </Box>
      <Image
        left="0"
        top={{ md: '450', base: '37%' }}
        src={flower}
        alt="flower icon"
        position="absolute"
        width={{ md: '150px', base: '100px' }}
      />
      <Image
        right="0"
        top={{ md: '7%', lg: '10%', base: '0' }}
        src={asterisk}
        alt="asterisk icon"
        position="absolute"
        width={{ md: '150px', base: '100px' }}
      />
    </Box>
  )
}
