import {
  Card,
  CardBody,
  Heading,
  Text,
  CardFooter,
  VStack,
  Box,
  CardHeader,
  List,
  ListItem,
} from '@chakra-ui/react'
import { ReactElement } from 'react'
import { CostCardType } from '../../../types'
import { Link } from '../Link'

export function CostCard({
  title,
  price,
  link,
  description,
  duration,
  savings,
  benefits,
  order,
}: CostCardType): ReactElement {
  const buttonText =
    title === 'Adaptive Tutoring' ? 'Chat on WhatsApp' : 'Register Now'
  return (
    <Box
      width={{ base: '100%', lg: '34%' }}
      mx="auto"
      my="20px"
      borderRadius="lg"
      p="2px"
      sx={{ order: order }}
      bgGradient="linear-gradient(to right, #FFC719 0%, #eb662d 51%, #FF2200 100%)"
    >
      <Card
        boxShadow="md"
        borderRadius="lg"
        width={{ base: '100%' }}
        height={{ base: '100%' }}
        data-testid="cost-card"
        bgColor="gray.200"
        data-group
        _hover={{
          backgroundImage:
            'linear-gradient(to right, #FFC719 0%, #eb662d 51%, #FF2200 100%)',
        }}
      >
        <CardHeader mb="auto">
          {description && (
            <Box
              width={'65%'}
              mx="auto"
              color={'white'}
              position={{ base: 'unset', lg: 'relative' }}
              marginTop={{ base: '-25px' }}
              bgGradient="linear-gradient(to right, #FFC719 0%, #eb662d 51%, #FF2200 100%)"
              borderRadius="lg"
              p={'2px'}
              animation={
                description ? 'rotateGradient 3s linear infinite' : 'none'
              }
              sx={{
                '@keyframes rotateGradient': {
                  '0%': { backgroundPosition: '0% 50%' },
                  '50%': { backgroundPosition: '100% 50%' },
                  '100%': { backgroundPosition: '0% 50%' },
                },
                backgroundSize: '200% 200%',
              }}
            >
              <Text
                fontSize={{ base: 'md' }}
                padding="2"
                width={'100%'}
                mx={'auto'}
                color="white"
                fontWeight={'extrabold'}
                borderRadius="lg"
                _groupHover={{
                  color: 'orange.700',
                  backgroundColor: 'gray.100',
                }}
                position={{ base: 'unset', lg: 'relative' }}
                bgColor="linear-gradient(to right, #FFC719 0%, #eb662d 51%, #FF2200 100%)"
              >
                {description}
              </Text>
            </Box>
          )}
          <Box p="2">
            <Text
              fontSize="lg"
              color="orange.700"
              fontWeight="semibold"
              _groupHover={{ color: 'white' }}
              marginTop={description ? 'unset' : '32px'}
            >
              {title}
            </Text>
          </Box>
        </CardHeader>
        <CardBody mb="auto" p="2">
          <VStack justifyContent="center" alignItems="center" gap="5" my="7">
            <Box>
              <Heading
                as="h4"
                fontSize="3xl"
                mb={0}
                _groupHover={{ color: 'white' }}
              >
                {price}
              </Heading>
              <Text
                fontSize="md"
                color="gray.600"
                fontWeight="700"
                _groupHover={{ color: 'white' }}
              >
                {duration}
              </Text>
            </Box>
          </VStack>
          {savings && (
            <Text
              fontSize="md"
              fontWeight={'bold'}
              color="red.300"
              mt={5}
              _groupHover={{ color: 'white' }}
            >
              {savings}
            </Text>
          )}
          <Box mt="5" width="90%" marginX="auto">
            <Heading
              as="h4"
              fontSize="lg"
              mb="3"
              _groupHover={{ color: 'white' }}
            >
              Benefits:
            </Heading>
            <List spacing={3} color="gray.700" _groupHover={{ color: 'white' }}>
              {benefits.map(benefit => (
                <ListItem key={benefit}>{benefit}</ListItem>
              ))}
            </List>
          </Box>
        </CardBody>
        <CardFooter mt="auto" flexDirection="column" gap="7" p="7">
          <Link
            variant="button"
            to={link}
            fontSize="md"
            width="70%"
            border="2px solid"
            marginX="auto"
            _groupHover={{ borderColor: 'gray.100' }}
          >
            {buttonText}
          </Link>
        </CardFooter>
      </Card>
    </Box>
  )
}
