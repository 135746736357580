import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react'
import React from 'react'
import aboutImage from '../../assets/about-page-image.jpg'
import { APPLICATION_MAX_WIDTH } from '../../constants'

export const WhoWeAre = (): React.ReactElement => {
  return (
    <Box
      maxWidth={APPLICATION_MAX_WIDTH}
      width={{ base: '100%', lg: '85%', xl: '70%' }}
      marginX="auto"
      textAlign={{ base: 'center', md: 'left' }}
      paddingY="10"
      paddingX="5"
    >
      <Heading as="h2" fontWeight="bold" marginBottom="5">
        Who We Are?
      </Heading>
      <Flex
        gap="5"
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          width={{ base: '100%', md: '55%' }}
          textAlign={{ base: 'justify', md: 'left' }}
        >
          <Text
            as="p"
            fontSize={{ base: 'md', md: 'lg' }}
            mb="3"
            data-testid="who-we-are-1"
            color="gray.800"
          >
            At Dot Campus, our journey began in 2020 with a simple but powerful
            idea: to bridge the gap between learning a skill and making an
            impact. What started as informal mentoring sessions for aspiring
            tech talents has grown into a thriving community dedicated to
            empowering young Africans to achieve their full potential.
          </Text>
          <Text
            as="p"
            fontSize={{ base: 'md', md: 'lg' }}
            mb="3"
            data-testid="who-we-are-2"
            color="gray.800"
          >
            We envision impacting over 1 million young Africans with high-income
            skills by 2032, we provide mentorship and support in a nurturing
            environment to foster success and innovation in their tech careers.
          </Text>
        </Box>
        <Box width={{ base: '100%', md: '50%' }}>
          <Image
            src={aboutImage}
            alt="about-page-logo"
            borderTopLeftRadius="100px"
            borderBottomRightRadius="100px"
            width={{ base: '100%', xl: 'auto' }}
            margin="auto"
          />
        </Box>
      </Flex>
    </Box>
  )
}
