import theoOkafor from '../assets/mentors/theo-okafor.webp'
import sullivan from '../assets/mentors/sullivan-wisdom.webp'
import Igwe from '../assets/mentors/chinonso-igwe.webp'
import Ayoola from '../assets/mentors/taiwo-ayoola.webp'
import Godwin_Amadi from '../assets/mentors/godwin-amadi.webp'
import maryChukwuma from '../assets/mentors/mary-brenda.webp'
import ritaMaria from '../assets/mentors/rita-maria.webp'
import abiolaBakare from '../assets/mentors/abiola-bakare.webp'
import goodnessOsumah from '../assets/mentors/goodness-osumah.webp'
import ezinneEgwu from '../assets/mentors/ezinne-egwu.webp'
import abiodunAbudu from '../assets/mentors/abiodun-abudu.webp'
import ayodeleSeriki from '../assets/mentors/ayodele-seriki.webp'
import benedictEjelonu from '../assets/mentors/benedict-ejelonu.webp'
import opeyemiAkanni from '../assets/mentors/opeyemi-akanni.webp'
import geraldineUgwu from '../assets/mentors/geraldine-ugwu.webp'
import jonathanAdah from '../assets/mentors/jonathan-adah.webp'
import uchenna from '../assets/mentors/uchenna.webp'

export interface Mentor {
  name: string
  image: string
  description: string
  linkedin: string
  github: string
  twitter: string
  website: string
}

export const mentors: Mentor[] = [
  {
    name: 'Theo Okafor',
    image: theoOkafor,
    description: 'Software Engineer',
    linkedin: 'https://www.linkedin.com/in/theo-okafor/',
    github: 'https://github.com/TheoOkafor',
    twitter: 'https://twitter.com/theookafor_',
    website: '',
  },
  {
    name: 'Sullivan Wisdom',
    image: sullivan,
    description: 'Frontend Developer',
    linkedin: 'https://www.linkedin.com/in/sullivanwisdom/',
    github: 'https://github.com/wiztemple',
    twitter: 'https://twitter.com/_wizsullivan',
    website: '',
  },
  {
    name: 'Rita-Maria Oladokun',
    image: ritaMaria,
    description: 'Fullstack Engineer',
    linkedin: 'https://www.linkedin.com/in/rita-maria-oladokun-346784194/',
    github: 'https://github.com/Riri202',
    twitter: 'https://twitter.com/greatbigriri',
    website: '',
  },
  {
    name: 'Goodness Osumah',
    image: goodnessOsumah,
    description: 'Data Analyst',
    linkedin: 'https://www.linkedin.com/in/goodness-osumah/',
    github: 'https://github.com/goodness001',
    twitter: '',
    website: '',
  },
  {
    name: 'Mary Chukwuma',
    image: maryChukwuma,
    description: 'Software Engineer',
    linkedin: 'https://www.linkedin.com/in/marychuks',
    github: 'https://github.com/brendagold',
    twitter: 'https://twitter.com/charmingbrenda',
    website: '',
  },
  {
    name: 'Jonathan Adah',
    image: jonathanAdah,
    description: 'Product Designer',
    linkedin: 'https://www.linkedin.com/in/jonathan-adah-49860a21a/',
    github: 'https://github.com/Jonathan-Tech-Sudo',
    twitter: '',
    website: '',
  },
  {
    name: 'Ayodele Seriki',
    image: ayodeleSeriki,
    description: 'DevOps Engineer',
    linkedin: 'https://www.linkedin.com/in/ayodele-seriki-a371b5186/',
    github: 'https://github.com/SerikiAyodele',
    twitter: 'https://twitter.com/techgirlxx',
    website: '',
  },
  {
    name: 'Geraldine Ugwu',
    image: geraldineUgwu,
    description: 'Software QA Engineer',
    linkedin: 'https://www.linkedin.com/in/geraldine-ugwu-127835147/',
    github: 'https://github.com/geraldine84',
    twitter: '',
    website: '',
  },
  {
    name: 'Ezinne Egwu',
    image: ezinneEgwu,
    description: 'Data Analyst',
    linkedin: 'https://www.linkedin.com/in/ezinne-egwu/',
    github: '',
    twitter: '',
    website: '',
  },
  {
    name: 'Benedict Ejelonu ',
    image: benedictEjelonu,
    description: 'Data Analyst',
    linkedin: 'https://www.linkedin.com/in/benedict-ejelonu/',
    github: 'https://github.com/Bwhiz',
    twitter: '',
    website: 'https://linktr.ee/Bwhiz',
  },
  {
    name: 'Opeyemi Akanni',
    image: opeyemiAkanni,
    description: 'Data Analyst',
    linkedin: 'https://www.linkedin.com/in/opeyemi-akanni-18841b153/',
    github: 'https://github.com/Opeyemidaniel',
    twitter: '',
    website: '',
  },
  {
    name: 'Chinonso Igwe',
    image: Igwe,
    description: 'Fullstack Engineer',
    linkedin: 'https://www.linkedin.com/in/igwe-chinonso/',
    github: 'https://github.com/chiboycalix',
    twitter: '',
    website: '',
  },
  {
    name: 'Abiola Bakare',
    image: abiolaBakare,
    description: 'Fullstack Developer',
    linkedin: 'https://www.linkedin.com/in/iamstarcode',
    github: 'https://github.com/iamstarcode',
    twitter: 'https://twitter.com/iamstarcode',
    website: '',
  },
  {
    name: 'Taiwo Ayoola',
    image: Ayoola,
    description: 'Software Engineer',
    linkedin: 'https://www.linkedin.com/in/taiwo-moruff-ayoola-39239012a/',
    github: 'https://github.com/Dev-Integral',
    twitter: 'https://x.com/dev_integral',
    website: '',
  },
  {
    name: 'Godwin Amadi',
    image: Godwin_Amadi,
    description: 'Software Developer',
    linkedin: 'https://www.linkedin.com/in/amadigodwin7/',
    github: 'https://github.com/Godwin-NJ',
    twitter: 'https://x.com/Godwin_NJ',
    website: '',
  },
  {
    name: 'Abiodun Abudu',
    image: abiodunAbudu,
    description: 'Software Engineer',
    linkedin: 'https://www.linkedin.com/in/abudu-abiodun-751b12b0/',
    github: 'https://github.com/sulenchy',
    twitter: 'https://x.com/ababsul',
    website: '',
  },
  {
    name: 'Uchenna Nnaike',
    image: uchenna,
    description: 'Software Engineer',
    linkedin: 'https://www.linkedin.com/in/nnaike-uchenna/',
    github: 'https://github.com/EricNnaike',
    twitter: '',
    website: '',
  },
]
