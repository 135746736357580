import {
  Card,
  CardBody,
  Heading,
  Image,
  Text,
  CardFooter,
} from '@chakra-ui/react'
import { ReactElement } from 'react'
import { ProgrammeType } from '../../types'
import { Link } from '../common'

export default function ShortCourseCard({
  title,
  description,
  image,
  link,
  duration,
}: ProgrammeType): ReactElement {
  return (
    <Card
      p="4"
      boxShadow="md"
      borderRadius="lg"
      minWidth={{ base: '200px', lg: '25%' }}
      data-testid="short-course-card"
    >
      <CardBody mb="4">
        <Image src={image} alt={title} borderRadius="lg" />

        <Heading
          size="md"
          fontSize={{ base: 'lg', lg: 'xl' }}
          color="primary.500"
          my="3"
        >
          {title}
        </Heading>
        <Text fontSize={{ base: 'md', lg: 'lg' }} color="primary.500">
          {duration}
        </Text>
        <Text fontSize={{ base: 'sm', lg: 'md' }} color="gray.800">
          {description}
        </Text>
      </CardBody>
      <CardFooter mt="auto">
        <Link
          variant="button"
          to={link}
          fontSize={{ base: 'sm', lg: 'lg' }}
          width="100%"
        >
          Register Now
        </Link>
      </CardFooter>
    </Card>
  )
}
