export const missionAndVision = [
  {
    title: 'Our Mission',
    content: 'To bridge the gap between learning a skill and making an impact.',
  },
  {
    title: 'Our Vision',
    content:
      'To equip over 1 million young Africans with high-income skills by 2032.',
  },
]
