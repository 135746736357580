import React from 'react'
import {
  HeroSection,
  CostSection,
  ProgrammesSection,
  ShortCoursesSection,
  RecommendationsSection,
} from '../components'
import { Mentors, Metadata } from '../components/common'

export const HomePage: React.FC = () => {
  return (
    <>
      <Metadata
        title="Dot Campus"
        description="Elevate your tech skills with our high-quality mentorship, collaborative learning environment, and resources tailored for tech talents. 
    We are an African tech community fostering growth in Data Analysis, Web Development, Frontend and Backend Engineering. 
    Soon expanding to Product Design, Cyber Security, Software QA Engineering, Cloud Computing/DevOps, etc. Providing accessible and effective learning opportunities for all"
      />
      <HeroSection />
      <RecommendationsSection />
      <ProgrammesSection />
      <CostSection />
      <ShortCoursesSection headerTextAlign="center" />
      <Mentors noOfMentors={8} />
    </>
  )
}
